<template>
    <div>
        <div class="card mb-0">
            <div class="card-header d-flex justify-content-between">
                <h5 class="modal-title" id="exampleModalLabel">{{ $t('calendrier-admin.edition-rdv') }}</h5>
                <span @click="onDismiss" class="clickable"><i class="fa fa-times"></i></span>
            </div>
            <div class="card-body">
                <div v-if="config.etablissement.display_honored_button" class="row">
                    <div class="col-md-6">
                        <div class="mr-2 mb-3">{{ $t('calendrier-admin.rdv-honore') }}</div>
                    </div>
                    <div class="col-md-6" v-bind:class="{ 'd-flex': !displayMobile }">
                        <label v-for="status in honoredStatus" class="radio-container mr-5">{{status.label}}
                            <input @click="onSetHonored(status.id)"
                                   type="radio"
                                   :checked="form.honored === status.id || (form.honored == null && status.id === 0)"
                                   name="radio">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-md-12">
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="create-date-debut">{{ $t('calendrier-admin.debut') }}</label>
                        <input id="create-date-debut" type="datetime-local" class="form-control" v-model="form.date_debut">
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="create-date-fin">{{ $t('calendrier-admin.fin') }}</label>
                        <input id="create-date-fin" type="datetime-local" class="form-control" v-model="form.date_fin">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <transition name="fade" mode="out-in">
                            <div class="d-flex flex-column justify-content-end h-100" v-if="getDoctors" key="show">
                                <label for="doctor" class="form-label">{{$t('global.docteur')}}</label>
                                <v-select id="doctor" :options="getDoctors" label="label" :value="getDoctors.find(d=> d.id === form.doctor_id)" @input="d=> d ? form.doctor_id = d.id : form.doctor_id = null"></v-select>
                            </div>
                        </transition>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="user" class="form-label m-0">{{ $t('calendrier-admin.patient') }} <em>{{ $t('calendrier-admin.nom-prenom-matricule') }}</em><br><em>{{ $t('calendrier-admin.introduire-min-3') }}</em></label>
                        <small class="d-block text-info" v-if="appointment && appointment.user_code !== null && appointment.user_code !== '??????'">Ce rendez-vous est lié à un patient dans une application externe, modification non permise.</small>
                        <v-select id="user" :disabled="appointment && appointment.user_code !== null && appointment.user_code !== '??????'" @search="onSearchUser" :options="userList" label="label" :value="userList.find(u=> u.id === form.user_id)" @input="u=> u ? this.form.user_id = u.id : this.form.user_id = null">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                    {{ $t('calendrier-admin.aucun-resultat-pour') }} : <em>{{ search }}</em>.
                                </template>
                                <em v-else style="opacity: 0.5">{{ $t('calendrier-admin.introduire-min-3') }}</em>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <transition name="fade" mode="out-in">
                            <div v-if="appointment" key="show">
                                <label for="reason" class="form-label">{{$t('global.motif')}}</label>
                                <v-select id="reason" class="w-100"
                                          :options="getReasons"
                                          label="label"
                                          @input="r=> r ? this.form.reason_id = r.id : this.form.reason_id = null"
                                          :value="getReasons.find(r=>r.id===form.reason_id)"
                                ></v-select>
                            </div>
                        </transition>
                    </div>
                    <div  class="col-md-6 mb-3">
                        <transition name="fade" mode="out-in">
                            <div v-if="appointment" key="show">
                                <label for="office" class="form-label">{{$t('global.lieu')}}</label>
                                <v-select id="office"
                                          :options="getOffices"
                                          label="name"
                                          @input="o=> o ? this.form.office_id = o.id : this.form.office_id = null"
                                          :value="getOffices.find(o=>o.id===form.office_id)"
                                ></v-select>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="create-date-debut">{{$t('global.commentaire')}}</label>
                        <textarea :readonly="getUser.id !== form.user_id" id="comment" class="form-control" rows="3" v-model="form.comment"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="note">{{$t('calendrier-admin.note')}}</label>
                        <textarea name="note" id="note" rows="3" class="form-control" v-model="form.note"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table mb-0">
                            <tr>
                                <td class="text-left">{{$t('calendrier-admin.patient')}}</td>
                                <td v-if="appointment && appointment.user">{{ appointment.user.full_name}}</td>
                                <td v-else>{{$t('calendrier-admin.patient-inconnu')}}</td>
                            </tr>
                            <tr v-if="appointment && appointment.user">
                                <td class="text-left">{{$t('calendrier-admin.matricule')}}</td>
                                <td>{{ appointment.user.matricule || 'Inconnu'}}</td>
                            </tr>
                            <tr v-if="appointment && appointment.user">
                                <td class="text-left">{{$t('calendrier-admin.email')}}</td>
                                <td>{{ appointment.user.email}}</td>
                            </tr>
                            <tr v-if="appointment && appointment.user">
                                <td class="text-left">{{$t('calendrier-admin.telephone')}}</td>
                                <td>{{ appointment.user.cellphone_number}}</td>
                            </tr>
                            <tr v-if="appointment">
                                <td class="text-left">{{$t('calendrier-admin.date-creation')}}</td>
                                <td>{{ appointment.created_at | displayDateTime }}</td>
                            </tr>
                            <tr v-if="appointment">
                                <td class="text-left">{{$t('calendrier-admin.cree-par')}}</td>
                                <!-- Le seul cas où on n'a pas de created_by c'est quand un rdv a été créé par l'API Graph -->
                                <td>{{ appointment.creator?.full_name || 'Microsoft' }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <span v-if="appointment" class="h4 text-primary clickable" v-tooltip="'Informations techniques'" data-toggle="collapse" data-target="#infos-techniques">
                    <i class="fas fa-info-circle"></i>
                </span>
                <div v-if="appointment" id="infos-techniques" class="row collapse" style="font-size: 12px">
                    <div class="col">
                        Internet: <br>{{appointment.id}}
                    </div>
                    <div class="col">
                        Externe: <br>{{appointment.emed_id}}
                    </div>
                    <div class="col">
                        Update: <br>{{appointment.updated_at | displayDateTime}}
                    </div>
                    <div v-if="appointment.ms_event_id" class="col-12">
                        Microsoft : {{appointment.ms_event_id}}
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="button" class="btn btn-secondary btn-sm mr-2" @click="onDismiss">Retour</button>
                <button v-if="config.etablissement.duplicate_appointment" type="button" class="btn btn-primary btn-sm mr-2" @click="openDuplicateModal">{{$t('global.dupliquer')}}</button>
                <button @click="onPrint" id="close-create-modal" type="button" class="btn btn-info btn-sm mr-2">{{$t('global.imprimer')}}</button>
                <button type="button" class="btn btn-danger btn-sm mr-2"  @click="onDelete()">
                    <i class="bi bi-trash-fill mr-2"></i>
                    {{$t('global.supprimer')}}
                </button>
                <button type="button" class="btn btn-primary btn-sm" @click="onConfirmation">
                    <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    <span v-if="loading">{{$t('global.chargement')}}</span>
                    <span v-if="!loading"><i class="bi bi-check mr-2"></i>{{$t('global.confirmer')}}
                </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {namespaces} from "../../../../../store/namespaces";
    import {EventBus} from "../../../../../event-bus";
    import calendarApi from '../../../../../api/calendar'
    import EHonoredStatus from "../../../../../enums/EHonoredStatus";
    import DeleteModal from "../delete/DeleteModal";
    import userApi from "../../../../../api/user";
    import PrintModal from "../print/PrintModal";
    import DuplicateModal from "../appointment/DuplicateModal"

    export default {
        name: "EditModal",
        props: {
            appointmentId: {
                required: true,
                type: Number
            }
        },
        data() {
            return {
                config: Vue.$config,
                loading: false,
                appointment: null,
                form: {
                    date_debut: null,
                    date_fin: null,
                    reason_id: null,
                    office_id: null,
                    doctor_id: null,
                    user_id: null,
                    comment: null,
                    note: null
                },
                honoredStatus: EHonoredStatus.getList(),
                userList: [],
                selectedUser: null,
                displayMobile: false
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
                getOffices: namespaces.adminCalendar + 'getOffices',
                getDoctors: namespaces.adminCalendar + 'getDoctors',
                getReasons: namespaces.adminCalendar + 'getReasons',
                getSelectedReason: namespaces.adminCalendar+'getSelectedReason',
                getSelectedDoctor: namespaces.adminCalendar+'getSelectedDoctor',
                getSelectedOffice: namespaces.adminCalendar+'getSelectedOffice',
                getSelectedSlot: namespaces.adminCalendar+'getSelectedSlot',
            }),
        },
        methods: {
            async init() {
                let res = await calendarApi.getAdminAppointmentById(this.appointmentId)
                this.appointment = res.data.appointment
                this.form = {
                    date_debut: this.appointment.date_debut,
                    date_fin: this.appointment.date_fin,
                    reason_id: this.appointment.reason_id,
                    office_id: this.appointment.office_id,
                    user_id: this.appointment.user_id,
                    doctor_id: this.appointment.doctor_id,
                    comment: this.appointment.comment,
                    note: this.appointment.note,
                    honored: this.appointment.honored
                }
                if(this.appointment.user) {
                    res = await userApi.searchTerm(this.appointment.user.last_name.slice(0, 4));
                    this.userList = res.data.users
                    this.selectedUser = this.userList.find(u=> u.id === this.form.user_id)
                }
            },
            async onConfirmation() {
                if(!this.appointment)return
                try {
                    await calendarApi.update(this.form,this.appointment.id)
                    this.$modal.hide('EditModal')
                    EventBus.$emit('admin-refresh')
                    this.$toast.open({
                        message: String(this.$t('calendrier-admin.rdv-success-update')),
                        type: 'success',
                    });
                }catch(e) {
                    this.$toast.open({
                        message: String(this.$t('global.erreur')),
                        type: 'error',
                    });
                }

            },
            onDismiss() {
                this.$modal.hide('EditModal')
            },
            onPrint() {
                this.$modal.show(PrintModal, {
                    appointment: this.appointment
                },{
                    name: 'PrintModal',
                    clickToClose: false,
                    adaptive: true,
                    height: 'auto',
                    scrollable: true,
                    transition: 'fade'
                })
            },
            async onSearchUser(search, loading) {
                if(search.length === 0)this.userList = []
                if(search.length > 2) {
                    let res = await userApi.searchTerm(search)
                    this.userList = res.data.users
                }
            },
            async onSetHonored(id) {
                if(!this.appointment)return
                this.honored = id
                let data = {
                    honored: this.honored
                }
                try {
                    await calendarApi.setHonored(data,this.appointment.id)
                    this.$toast.open({
                        message: String(this.$t('calendrier-admin.status-update-success')),
                        type: 'success',
                    });
                    EventBus.$emit('admin-refresh')
                }catch (e) {
                    this.$toast.open({
                        message: String(this.$t('global.erreur')),
                        type: 'error',
                    });
                }
            },
            onDelete() {
                this.$modal.show(DeleteModal,{
                    appointment: this.appointment
                },{
                    name: 'DeleteModal',
                    clickToClose: false,
                    adaptive: true,
                    height: 'auto',
                    scrollable: true,
                    transition: 'fade'
                })
            },
            openDuplicateModal() {
                this.$modal.show(DuplicateModal,{
                    appointment: this.appointment
                },{
                    name: 'DuplicateModal',
                    adaptive: true,
                    height: 'auto',
                    width: '600px',
                    clickToClose: false,
                    scrollable: true,
                    transition: 'fade'
                })
            }
        },
        mounted() {
            this.init()
            this.displayMobile = window.matchMedia( "(max-width: 768px)").matches
        }
    }
</script>

<style scoped>
    .btn-primary, .btn-primary:hover {
        color: #ffffff;
        font-size: 0.85rem;
    }
</style>
